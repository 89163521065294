import styled from "styled-components";
import {Link as RouterLink} from "react-router-dom";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

export const Title = styled.h1`
  font-size: 22px;
  border-left: 1px solid #444;
  display: block;
  height: 40px;
  line-height: 40px;
  padding-left: 12px;
`;

export const Navigation = styled.nav`
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;
  margin-left: 40px;
  flex-grow: 1;
`;

export const Link = styled(RouterLink)`
  color: whitesmoke;
  text-decoration: none;
  display: block;
  padding: 0 20px;
  position: relative;
  &.selected::after {
    height: 2px;
  }
  &::after {
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 0;
    background-color: #f50057;
    content: '';
    transition: height 300ms ease-in-out;
  }
  &:hover::after {
    height: 4px;
  }
`;

export const UserBlock = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
  align-items: center;
  color: rgb(187, 187, 187);
`;

export const Avatar = styled.img`
  height: 40px;
  border-radius: 4px;
`;
