import React, {useEffect, useState} from "react";
import Dialog from "@jetbrains/ring-ui/dist/dialog/dialog";
import Select from "@jetbrains/ring-ui/dist/select/select";
import Button from "@jetbrains/ring-ui/dist/button/button";
import Loader from "@jetbrains/ring-ui/dist/loader/loader";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {ProductDescriptor} from "../../types/product-descriptor";
import {BlogPost, EditedBlogPost} from "../../types/blog-post";
import {PostsListItem} from "../PostsListItem/PostsListItem";
import {API} from "../../services/api";
import {digestSetupActions} from "../../redux/digest-setup/digest-setup-slice";
import * as S from "./styles";
import {Size} from "@jetbrains/ring-ui/dist/input/input";

interface Props {
    onClose(): void;
}

export const CrosspostingDialog: React.FC<Props> = (props) => {

    const {onClose} = props;

    const dispatch = useAppDispatch();
    const {products} = useAppSelector(state => state.blogPosts);
    const {selectedPosts} = useAppSelector(state => state.digestSetup);

    const [selectedCrosspostingProduct, setSelectedCrosspostingProduct] = useState<ProductDescriptor | null>(null);
    const [loadedPosts, setLoadedPosts] = useState<BlogPost[] | null>(null);

    useEffect(() => {
        setLoadedPosts(null);
        if (selectedCrosspostingProduct) {
            API.loadPosts(selectedCrosspostingProduct.prettyName).subscribe(
                (posts) => {
                    setLoadedPosts(posts);
                },
                err => {
                    // TODO
                },
            );
        }
    }, [selectedCrosspostingProduct]);

    const onPostSelected = (post: BlogPost) => {
        dispatch(digestSetupActions.setSelectedPosts([...selectedPosts, EditedBlogPost.from(post)]));
        onClose();
    };

    return <Dialog show={true} onCloseAttempt={onClose}>
        <S.DialogContent>
            <h2 style={{marginTop: 0}}>Cross-posting</h2>
            <S.InfoMessage>You can manually pick posts related to other products.</S.InfoMessage>
            <S.Subtitle>Product:</S.Subtitle>
            <Select
                data={(products ?? []).map(p => ({key: p.name, label: p.prettyName, value: p}))}
                selected={selectedCrosspostingProduct && {label: selectedCrosspostingProduct.prettyName, value: selectedCrosspostingProduct, key: selectedCrosspostingProduct.name}}
                onChange={(item: any) => setSelectedCrosspostingProduct(item.value)}
                size={Size.FULL}
                filter={true}
            />
            {selectedCrosspostingProduct && <>
                <S.Subtitle style={{marginTop: "16px"}}>Available posts:</S.Subtitle>
                <S.PostsList>
                    {loadedPosts?.map(post =>
                        <PostsListItem
                            key={post.id}
                            title={post.title}
                            selected={false}
                            stateInDigest={post.stateInDigest}
                            sentFromJetBlog={post.sentFromJetBlog}
                            onSelected={() => onPostSelected(post)}
                        />
                    ) ?? <Loader message="Loading posts"/>}
                </S.PostsList>
            </>}
            <S.ButtonsBlock>
                <Button onClick={onClose}>Cancel</Button>
            </S.ButtonsBlock>
        </S.DialogContent>
    </Dialog>;

};
