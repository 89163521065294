import styled from "styled-components";

export const DialogContent = styled.div`
  padding: 16px;
`;

export const Subtitle = styled.h2`
  font-size: 12px;
  font-weight: 400;
  color: #999;
  letter-spacing: 1.2px;
  margin: 0;
  padding: 5px 0;
  text-transform: uppercase;
`;

export const PostsList = styled.div`
  height: 300px;
  overflow-y: auto;
`;

export const ButtonsBlock = styled.div`
  float: right;
  margin-top: 8px;
`;

export const InfoMessage = styled.p`
  color: #777;
  font-size: 0.9em;
`;