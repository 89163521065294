import styled from "styled-components";

export const ItemContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  button {
    padding: 2px 4px;
  }
  svg {
    flex-shrink: 0;
  }
`;
