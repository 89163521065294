import React, {BaseSyntheticEvent, useEffect, useState} from "react";
import Select from "@jetbrains/ring-ui/dist/select/select";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import Input from "@jetbrains/ring-ui/dist/input/input";
import Button from "@jetbrains/ring-ui/dist/button/button";
import ButtonGroup from "@jetbrains/ring-ui/dist/button-group/button-group";
import Confirm from "@jetbrains/ring-ui/dist/confirm/confirm";
import alert from "@jetbrains/ring-ui/dist/alert-service/alert-service";
import {PostsListItem} from "../PostsListItem/PostsListItem";
import {BlogPost, EditedBlogPost} from "../../types/blog-post";
import {EditPostDialog, EditPostDialogProps} from "../EditPostDialog/EditPostDialog";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DraggableElement} from "../DraggableElement/DraggableElement";
import {subscribeActionAfter} from "redux-subscribe-action";
import {digestSetupActions} from "../../redux/digest-setup/digest-setup-slice";
import {sendoutsActions} from "../../redux/sendouts/sendouts-slice";
import {blogPostsActions} from "../../redux/blog-posts/blog-posts-slice";
import * as S from "./styles";
import {CrosspostingDialog} from "../CrosspostingDialog/CrosspostingDialog";
import {Size} from "@jetbrains/ring-ui/dist/input/input";
import {Product} from "../../types/product";

const ALERTS_TIMEOUT_MS = 5000;

export const Controls = () => {

    const dispatch = useAppDispatch();
    const {
        selectedProductName,
        introduction,
        emailSubject,
        subheader,
        selectedPosts,
        setupIsValid,
        senderName,
    } = useAppSelector(state => state.digestSetup);
    const {products, loadedPosts} = useAppSelector(state => state.blogPosts);
    const {sampleEmailSendingInProgress, digestSendingInProgress, sendoutAllowed} = useAppSelector(state => state.sendouts);

    const [editPostDialogProps, setEditPostDialogProps] = useState<EditPostDialogProps | null>(null);
    const [showSendDigestConfirmation, setSendDigestConfirmationState] = useState(false);
    const [showCrosspostingDialog, setShowCrosspostingDialog] = useState(false);

    const unselectedPosts = loadedPosts?.filter(p => !selectedPosts.some(_p => p.id === _p.id)) ?? [];
    const allowedProducts = products
        ?.filter(product => product.allowed)
        .sort((a, b) => a.prettyName < b.prettyName ? -1 : (a.prettyName > b.prettyName ? 1 : 0));

    const selectedProductPrettyName = products?.find(p => p.name === selectedProductName)?.prettyName ?? null;

    useEffect(() => {
        if (products === null) {
            dispatch(blogPostsActions.loadProducts());
        }
        const unsubscribe1: Function = subscribeActionAfter(sendoutsActions.onTestEmailSent.type, (_) => {
            alert.message("Successfully sent a sample email", ALERTS_TIMEOUT_MS);
        });
        const unsubscribe2: Function = subscribeActionAfter(sendoutsActions.onDigestSent.type, (_) => {
            alert.message("Marketo campaign has been started", ALERTS_TIMEOUT_MS);
        });
        return () => {
            unsubscribe1();
            unsubscribe2();
        };
    }, []);

    const onProductChange = async (item: any) => {
        const productName: Product = item.value;
        dispatch(digestSetupActions.setProduct(productName));
    };

    const onPostSelected = (post: BlogPost) => {
        dispatch(digestSetupActions.setSelectedPosts([...selectedPosts, EditedBlogPost.from(post)]));
    };

    const onPostUnselected = (postId: string) => {
        dispatch(digestSetupActions.setSelectedPosts(selectedPosts.filter(_post => _post.id !== postId)));
    };

    const editPost = (post: EditedBlogPost) => {
        setEditPostDialogProps({
            post,
            onClose: () => setEditPostDialogProps(null),
        });
    };

    const sendDigest = () => {
        dispatch(sendoutsActions.sendDigest());
        setSendDigestConfirmationState(false);
    };

    const onPostsReorder = (fromIndex: number, toIndex: number) => {
        const posts = [...selectedPosts];
        const postToMove = posts[fromIndex];
        posts.splice(fromIndex, 1);
        posts.splice(toIndex, 0, postToMove);
        dispatch(digestSetupActions.setSelectedPosts(posts));
    };

    let selectedPostsElements: any = [];
    try {
        selectedPostsElements = selectedPosts.length > 0
            ? <DndProvider backend={HTML5Backend}>
                {selectedPosts.map((post, idx) => {
                    return <DraggableElement id={post.id} key={post.id} index={idx} onMove={onPostsReorder}>
                        <PostsListItem
                            title={post.title}
                            stateInDigest={post.meta.stateInDigest}
                            sentFromJetBlog={post.meta.sentFromJetBlog}
                            selected={true}
                            onUnselected={() => onPostUnselected(post.id)}
                            onEditButtonClick={() => editPost(post)}
                        />
                    </DraggableElement>;
                })}
            </DndProvider>
            : <S.NoContentPlaceholder/>;
    } catch (e) {
        selectedPostsElements = <S.NoContentPlaceholder text="Some selected posts are missing" />;
    }

    return <S.ControlsContainer>
        <S.Subtitle>Product</S.Subtitle>
        <Select
            data={(allowedProducts ?? []).map(p => ({key: p.name, label: p.prettyName, value: p.name}))}
            selected={selectedProductName !== null ? {label: selectedProductPrettyName, value: selectedProductName, key: selectedProductName} : null}
            onChange={onProductChange}
            size={Size.FULL}
            filter={true}
        />

        <S.Subtitle style={{marginTop: "16px"}}>Email subject</S.Subtitle>
        <Input
            onChange={(e: BaseSyntheticEvent) => dispatch(digestSetupActions.setSubject(e.target.value))}
            value={emailSubject ?? ""}
            size={Size.FULL}
            placeholder={selectedProductName ? "Enter an email's subject here" : "Please select product first"}
            error={emailSubject || !selectedProductName ? null : "This value must not be empty"}
        />

        <S.Subtitle style={{marginTop: "16px"}}>Sender name</S.Subtitle>
        <Input
            onChange={(e: BaseSyntheticEvent) => dispatch(digestSetupActions.setSenderName(e.target.value))}
            value={senderName ?? ""}
            size={Size.FULL}
            placeholder={selectedProductName ? "Enter an email's sender name here" : "Please select product first"}
            error={senderName || !selectedProductName ? null : "This value must not be empty"}
        />

        <S.Subtitle style={{marginTop: "16px"}}>Digest subheader</S.Subtitle>
        <Input
            onChange={(e: BaseSyntheticEvent) => dispatch(digestSetupActions.setSubheader(e.target.value))}
            value={subheader ?? ""}
            size={Size.FULL}
            placeholder={selectedProductName ? "Enter a subheader here" : "Please select product first"}
            error={subheader || !selectedProductName ? null : "This value must not be empty"}
        />

        <S.Subtitle style={{marginTop: "16px"}}>Digest introduction / Preheader (Markdown supported)</S.Subtitle>
        <Input
            multiline
            onChange={(e: BaseSyntheticEvent) => dispatch(digestSetupActions.setIntroduction(e.target.value))}
            value={introduction ?? ""}
            size={Size.FULL}
            placeholder={selectedProductName ? "Enter an introduction text here" : "Please select product first"}
            error={introduction || !selectedProductName ? null : "This value must not be empty"}
        />

        <S.Subtitle style={{marginTop: "16px", display: "block", marginRight: "16px"}}>Product blogs</S.Subtitle>

        <div style={{display: "flex", flexFlow: "row nowrap", height: "400px", margin: "2px 0 16px"}}>
            <S.PostsContainer>
                {(loadedPosts || !selectedProductName) && selectedPostsElements || S.LoadingPostsPlaceholder}
            </S.PostsContainer>
            <S.PostsContainer>
                {(loadedPosts || !selectedProductName) && <>
                    {unselectedPosts.length > 0
                        ? unselectedPosts.map((post) => <PostsListItem
                            title={post.title}
                            stateInDigest={post.stateInDigest}
                            sentFromJetBlog={post.sentFromJetBlog}
                            key={post.id}
                            selected={false}
                            onSelected={() => onPostSelected(post)}
                        />)
                        : <S.NoContentPlaceholder/>
                    }
                </> || S.LoadingPostsPlaceholder}
            </S.PostsContainer>
        </div>

        <ButtonGroup style={{textAlign: "center", display: "block", boxShadow: "none"}}>
            <Button
                onClick={() => setShowCrosspostingDialog(true)}
                disabled={!selectedProductName}
            >
                Cross-post
            </Button>
            <Button
                onClick={() => dispatch(sendoutsActions.sendTestEmail())}
                loader={sampleEmailSendingInProgress}
                disabled={!setupIsValid}
            >
                Send to myself
            </Button>
            <Button
                disabled={!setupIsValid || !sendoutAllowed}
                onClick={() => setSendDigestConfirmationState(true)}
                danger={true}
            >
                Send digest
            </Button>
        </ButtonGroup>

        {editPostDialogProps && <EditPostDialog {...editPostDialogProps}/>}
        {showCrosspostingDialog && <CrosspostingDialog onClose={() => setShowCrosspostingDialog(false)}/>}

        <Confirm
            show={showSendDigestConfirmation || digestSendingInProgress}
            text="Run the campaign?"
            description={<p>In case of any issues, only <b>#email-marketing</b> team members can stop a sendout manually.</p>}
            confirmLabel="Yep, I´m absolutely sure"
            rejectLabel="Cancel"
            onReject={() => setSendDigestConfirmationState(false)}
            onConfirm={sendDigest}
            inProgress={digestSendingInProgress}
        />

    </S.ControlsContainer>;

}
