import {combineEpics, Epic, ofType, StateObservable} from "redux-observable";
import {RootState} from "../store";
import {sendoutsActions} from "./sendouts-slice";
import {catchError, map, of, switchMap} from "rxjs";
import {API} from "../../services/api";
import {extractSetup} from "../../utils/utils";
import {miscActions} from "../misc/misc-slice";

const updateSendoutAllowance$: Epic = (action$, state: StateObservable<RootState>) => action$.pipe(
    ofType(sendoutsActions.updateSendoutAllowance),
    switchMap(() => API.getSendoutAllowance(state.value.digestSetup.selectedProductName!).pipe(
        map(allowed => sendoutsActions.onSendoutAllowanceUpdated(allowed)),
        catchError(e => of(miscActions.onApiCallError(e))),
    )),
);

const sendTestEmail$: Epic = (action$, state: StateObservable<RootState>) => action$.pipe(
    ofType(sendoutsActions.sendTestEmail),
    switchMap(() => API.sendTestEmail(extractSetup(state.value)).pipe(
        map(() => sendoutsActions.onTestEmailSent()),
        catchError(e => of(miscActions.onApiCallError(e))),
    )),
);

const sendDigest$: Epic = (action$, state: StateObservable<RootState>) => action$.pipe(
    ofType(sendoutsActions.sendDigest),
    switchMap(() => API.sendDigest(extractSetup(state.value)).pipe(
        map(() => sendoutsActions.onDigestSent()),
        catchError(e => of(miscActions.onApiCallError(e))),
    )),
);

export const sendoutsEpic = combineEpics(
    updateSendoutAllowance$,
    sendTestEmail$,
    sendDigest$,
);
