import {DigestSendout} from "../../types/digest-sendout";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface HistoryState {
    history: DigestSendout[] | null;
}

const initialState: HistoryState = {
    history: null,
};

export const historySlice = createSlice({
    name: "history",
    initialState,
    reducers: {
        loadHistory: (state) => {
            state.history = null;
        },
        onHistoryLoaded: (state, action: PayloadAction<DigestSendout[]>) => {
            state.history = action.payload;
        },
    },
});

export const historyReducer = historySlice.reducer;
export const historyActions = historySlice.actions;
