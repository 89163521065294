import {combineEpics, Epic, ofType, StateObservable} from "redux-observable";
import {RootState} from "../store";
import {catchError, ignoreElements, map, of, switchMap, tap} from "rxjs";
import {API} from "../../services/api";
import {extractSetup} from "../../utils/utils";
import {history} from "../../components/CustomRouter/CustomRouter";
import {persistenceActions} from "./persistence-slice";
import {miscActions} from "../misc/misc-slice";
import {AppConfig} from "../../config";
import {PayloadAction} from "@reduxjs/toolkit";

const updatePreviewLink$: Epic = (action$, state: StateObservable<RootState>) => action$.pipe(
    ofType(persistenceActions.updatePreviewLink),
    switchMap(() => API.updatePreview(extractSetup(state.value)).pipe(
        map(previewLink => persistenceActions.setPreviewLink(previewLink)),
        catchError(e => of(miscActions.onApiCallError(e))),
    )),
);

const updateHistoryId$: Epic = (action$, state: StateObservable<RootState>) => action$.pipe(
    ofType(persistenceActions.updateHistoryId),
    switchMap(() => API.updateHistoryId(extractSetup(state.value)).pipe(
        map(historyId => persistenceActions.setHistoryId(historyId)),
        catchError(e => of(miscActions.onApiCallError(e))),
    )),
);

const onHistoryIdUpdated$: Epic = (action$) => action$.pipe(
    ofType(persistenceActions.setHistoryId),
    tap((action: PayloadAction<string>) => {
        const historyId = action.payload;
        const path = `${AppConfig.rootPath}editor/${historyId}`;
        console.log('%cnavigating to ' + path, 'color: coral');
        history.push(path);
    }),
    ignoreElements(),
);

export const persistenceEpic = combineEpics(
    updatePreviewLink$,
    updateHistoryId$,
    onHistoryIdUpdated$,
);
