import styled from "styled-components";
import React from "react";
import {FaEyeSlash} from "react-icons/fa";
import Loader from "@jetbrains/ring-ui/dist/loader/loader";

export const Subtitle = styled.h2`
  font-size: 12px;
  font-weight: 400;
  color: #999;
  letter-spacing: 1.2px;
  margin: 0;
  padding: 5px 0;
  text-transform: uppercase;
`;

export const ControlsContainer = styled.div`
  padding: 8px;
  height: calc(100vh - 104px); // TODO: fix it somehow
  overflow-y: auto;
  border-left: 1px solid #CCC;
`;

export const PostsContainer = styled.div`
  border: 1px solid #DDD;
  flex: 1 0;
  height: 100%;
  overflow-y: auto;
  position: relative;
  padding: 4px;
  font-size: 0.8em;
`;

export const NoContentPlaceholder: React.FC<{text?: string}> = (props) => <div style={{
    textAlign: "center",
    position: "absolute",
    margin: "auto",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    height: "100px",
    color: "#AAA",
}}>
    <FaEyeSlash/>
    <p style={{fontSize: "0.8em"}}>{props.text ?? "No content"}</p>
</div>;

export const LoadingPostsPlaceholder = <div style={{margin: "auto", marginTop: "100px", transform: "scale(0.7)"}}>
    <Loader message="Loading posts..." />
</div>;
