import {combineEpics, Epic, ofType, StateObservable} from "redux-observable";
import {catchError, from, map, of, switchMap} from "rxjs";
import {API} from "../../services/api";
import {RootState} from "../store";
import {PayloadAction} from "@reduxjs/toolkit";
import {digestSetupActions} from "../digest-setup/digest-setup-slice";
import {EditedBlogPost} from "../../types/blog-post";
import {blogPostsActions, LoadPostsActionPayload} from "./blog-posts-slice";
import {miscActions} from "../misc/misc-slice";

const loadProducts$: Epic = (action$) => action$.pipe(
    ofType(blogPostsActions.loadProducts),
    switchMap(_ => API.getProductsList().pipe(
        map(products => blogPostsActions.onProductsLoaded(products)),
    )),
    catchError(e => of(miscActions.onApiCallError(e))),
);

const loadPosts$: Epic = (action$, state: StateObservable<RootState>) => action$.pipe(
    ofType(blogPostsActions.loadPosts),
    switchMap((action: PayloadAction<LoadPostsActionPayload>) => API.loadPosts(
        state.value.digestSetup.selectedProductName!,
    ).pipe(
        switchMap(posts => from([
            blogPostsActions.setLoadedPosts(posts),
            ...(action.payload.preserveSelected
                    ? []
                    : [digestSetupActions.setSelectedPosts(posts.slice(0, 10).map(post => EditedBlogPost.from(post)))]
            ),
        ])),
        catchError(e => of(miscActions.onApiCallError(e))),
    )),
);

export const blogPostsEpic = combineEpics(
    loadProducts$,
    loadPosts$,
);
