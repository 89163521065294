import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ProductDescriptor} from "../../types/product-descriptor";
import {BlogPost} from "../../types/blog-post";

export type LoadPostsActionPayload = {preserveSelected: boolean};

export interface BlogPostsState {
    products: ProductDescriptor[] | null;
    loadedPosts: BlogPost[] | null;
}

const initialBlogPostsState: BlogPostsState = {
    products: null,
    loadedPosts: null,
};

const blogPostsSlice = createSlice({
    initialState: initialBlogPostsState,
    name: "blog-posts-slice",
    reducers: {
        loadProducts: () => {},
        onProductsLoaded: (state, action: PayloadAction<ProductDescriptor[]>) => {
            state.products = action.payload;
        },
        loadPosts: (state, action: PayloadAction<LoadPostsActionPayload>) => {},
        setLoadedPosts: (state, action: PayloadAction<BlogPost[] | null>) => {
            state.loadedPosts = action.payload;
        },
    },
});

export const blogPostsActions = blogPostsSlice.actions;

export const blogPostsReducer = blogPostsSlice.reducer;
