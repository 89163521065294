import React, {useEffect} from "react";
import logo from "../../assets/images/mau-logo.png";
import * as S from "./styles";
import RingHeader from '@jetbrains/ring-ui/dist/header/header';
import {Page} from "../../types/pages";
import classNames from "classnames";
import {useLocation} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {usersActions} from "../../redux/users/users-slice";

export const Header: React.FC = () => {

    const {pathname} = useLocation();
    const dispatch = useAppDispatch();
    const currentPage = resolveCurrentPage(pathname);
    const currentUser = useAppSelector(state => state.users.currentUser);

    useEffect(() => {
        if (currentUser === null) {
            dispatch(usersActions.loadCurrentUser());
        }
    }, []);

    return <RingHeader>
        <S.Container>
            <img src={logo} style={{height: "48px", marginRight: "12px"}} />
            <S.Title>Email Digests</S.Title>
            <S.Navigation>
                <S.Link to={Page.EDITOR} className={classNames({selected: currentPage === Page.EDITOR})}>Editor</S.Link>
                <S.Link to={Page.HISTORY} className={classNames({selected: currentPage === Page.HISTORY})}>History</S.Link>
            </S.Navigation>
            {currentUser && <S.UserBlock>
                <S.Avatar src={currentUser.picture}/>
                <span>{currentUser.name.split(" ")[0]}</span>
            </S.UserBlock>}
        </S.Container>
    </RingHeader>;

};

function resolveCurrentPage(path: string): Page | undefined {
    if (path === Page.EDITOR || path.startsWith("/editor")) {
        return Page.EDITOR;
    } else if (path === Page.HISTORY) {
        return Page.HISTORY;
    } else {
        return undefined;
    }
}
