import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const Iframe = styled.iframe`
  border-width: 0;
  width: 100%;
  height: 100%;
`;
