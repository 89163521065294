import styled from "styled-components";

export const IFrame = styled.iframe`
  border-width: 0;
  width: 100%;
  height: 100%;
`;

export const LoaderOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const LoaderAnimationHolder = styled.div`
  position: absolute;
  inset: 0;
  margin: auto;
  height: 130px;
  width: 200px;
  border-radius: 16px;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
`;
