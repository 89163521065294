import {combineEpics, Epic, ofType} from "redux-observable";
import {usersActions} from "./users-slice";
import {catchError, map, of, switchMap} from "rxjs";
import {API} from "../../services/api";
import {miscActions} from "../misc/misc-slice";

const loadCurrentUser$: Epic = (action$) => action$.pipe(
    ofType(usersActions.loadCurrentUser),
    switchMap(() => API.getCurrentUserInfo().pipe(
        map(userInfo => usersActions.onCurrentUserLoaded(userInfo)),
        catchError(e => of(miscActions.onApiCallError(e))),
    )),
);

export const usersEpic = combineEpics(
    loadCurrentUser$,
);
