import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {parseHistoryIdFromPath} from "../../utils/utils";

export interface PersistenceState {
    previewLink: string | null;
    historyId: string | null;
    updatingPreview: boolean;
}

const initialPersistenceState: PersistenceState = {
    previewLink: null,
    historyId: parseHistoryIdFromPath(),
    updatingPreview: false,
};

const persistenceSlice = createSlice({
    initialState: initialPersistenceState,
    name: "persistence-slice",
    reducers: {
        updatePreviewLink: (state) => {
            state.updatingPreview = true;
        },
        setPreviewLink: (state, action: PayloadAction<string>) => {
            state.previewLink = action.payload;
            state.updatingPreview = false;
        },

        updateHistoryId: () => {},
        setHistoryId: (state, action: PayloadAction<string>) => {
            state.historyId = action.payload;
        },
    },
});

export const persistenceActions = persistenceSlice.actions;

export const persistenceReducer = persistenceSlice.reducer;
