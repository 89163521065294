import {ProductDescriptor} from "../../types/product-descriptor";
import {EditedBlogPost, IncompleteDigestSetup} from "../../types/blog-post";
import {createSlice, Draft, PayloadAction} from "@reduxjs/toolkit";
import {Product} from "../../types/product";
import {ProductDefaults} from "../../types/product-defaults";
import {DigestSetup} from "../../types/digest-setup";

export interface DigestSetupState {
    selectedProductName: Product | null;
    emailSubject: string | null;
    introduction: string | null;
    subheader: string | null;
    selectedPosts: EditedBlogPost[];
    senderName: string | null;
    setupIsValid: boolean;
}

const initialDigestSetupSlice: DigestSetupState = {
    selectedProductName: null,
    emailSubject: null,
    introduction: null,
    subheader: null,
    selectedPosts: [],
    senderName: null,
    setupIsValid: false,
};

const digestSetupSlice = createSlice({
    initialState: initialDigestSetupSlice,
    name: "digest-setup-slice",
    reducers: {
        setProduct: (state, action: PayloadAction<Product>) => {
            state.selectedProductName = action.payload;
            state.setupIsValid = isSetupValid(state);
        },
        loadProductDefaults: (state, action: PayloadAction<Product>) => {},
        onProductDefaultsLoaded: (state, action: PayloadAction<ProductDefaults>) => {
            state.emailSubject = action.payload.subject;
            state.introduction = action.payload.introduction;
            state.subheader = action.payload.subheader;
            state.senderName = action.payload.senderName;
            state.setupIsValid = isSetupValid(state);
        },
        setSubject: (state, action: PayloadAction<string>) => {
            state.emailSubject = action.payload;
            state.setupIsValid = isSetupValid(state);
        },
        setSenderName: (state, action: PayloadAction<string>) => {
            state.senderName = action.payload;
            state.setupIsValid = isSetupValid(state);
        },
        setIntroduction: (state, action: PayloadAction<string>) => {
            state.introduction = action.payload;
            state.setupIsValid = isSetupValid(state);
        },
        setSubheader: (state, action: PayloadAction<string>) => {
            state.subheader = action.payload;
            state.setupIsValid = isSetupValid(state);
        },
        setSelectedPosts: (state, action: PayloadAction<EditedBlogPost[]>) => {
            state.selectedPosts = action.payload;
        },
        loadStateByHistoryId: (state, action: PayloadAction<string>) => {},
        onIncompleteDigestSetupLoadedByHistoryId: (state, action: PayloadAction<IncompleteDigestSetup>) => {},
        onDigestSetupLoadedByHistoryId: (state, action: PayloadAction<DigestSetup>) => {
            state.selectedPosts = action.payload.include;
            state.introduction = action.payload.introduction;
            state.selectedProductName = action.payload.product;
            state.subheader = action.payload.subheader;
            state.senderName = action.payload.senderName;
            state.emailSubject = action.payload.subject;
            state.setupIsValid = isSetupValid(state);
        },
    },
});

export const digestSetupActions = digestSetupSlice.actions;

export const digestSetupReducer = digestSetupSlice.reducer;

function isSetupValid(state: Draft<DigestSetupState>): boolean {
    return state.selectedProductName != null && !!state.introduction && !!state.subheader && !!state.emailSubject && !!state.senderName;
}
