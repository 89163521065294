import {RootState} from "../redux/store";
import {DigestSetup} from "../types/digest-setup";
import {AppConfig} from "../config";

export function parseHistoryIdFromPath(path?: string): string | null {
    if (path === undefined) {
        path = window.location.pathname;
    }
    path = path.replace(AppConfig.rootPath, '/');
    const tokens = path.split('/');
    if (tokens.length === 3 && tokens[2] !== 'editor') {
        return tokens[2];
    } else {
        return null;
    }
}

export function extractSetup(rootState: RootState): DigestSetup {
    const product = rootState.digestSetup.selectedProductName;
    const introduction = rootState.digestSetup.introduction;
    const subheader = rootState.digestSetup.subheader;
    const subject = rootState.digestSetup.emailSubject;
    const include = rootState.digestSetup.selectedPosts;
    const senderName = rootState.digestSetup.senderName;
    if (!product || !introduction || !subheader || !subject || !senderName) {
        throw Error("Incomplete application state error");
    }
    return {
        product,
        introduction,
        subheader,
        subject,
        include,
        senderName,
    };
}
