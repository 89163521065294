import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {AppRoot} from "./components/AppRoot/AppRoot";
import {Provider} from "react-redux";
import {store} from "./redux/store";
import '@jetbrains/ring-ui/dist/style.css';
import {CustomRouter} from "./components/CustomRouter/CustomRouter";

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(<React.StrictMode>
    <Provider store={store}>
        <CustomRouter basename={"/"}>
            <AppRoot/>
        </CustomRouter>
    </Provider>
</React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
