import {Product} from "./product";
import {DigestSetup} from "./digest-setup";
import {IncompleteDigestSetup} from "./blog-post";

export class DigestSendout {
    public readonly sentAt: Date;
    public readonly configuration: IncompleteDigestSetup;
    constructor(
        public readonly id: string,
        public readonly product: Product,
        sentAt: string,
        public readonly sentBy: string,
        public readonly html: string,
        public readonly status: DigestStatus,
        configuration: IncompleteDigestSetup,
    ) {
        this.sentAt = new Date(sentAt);
        this.configuration = configuration;
    }
    static parse(obj: any): DigestSendout {
        return new DigestSendout(
            obj.id,
            obj.product,
            obj.sentAt,
            obj.sentBy,
            obj.html,
            obj.status,
            obj.configuration,
        );
    }
}

export enum DigestStatus {
    SENT = "SENT",
    ERROR = "ERROR",
}
