import React from "react";
import {Preview} from "../Preview/Preview";
import {Controls} from "../Controls/Controls";

export const EditorPage: React.FC = () => {

    return <>
        <div id="main-content-left">
            <Preview/>
        </div>
        <div id="main-content-right">
            <Controls />
        </div>
    </>;

};
