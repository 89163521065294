import {combineEpics, Epic, ofType} from "redux-observable";
import {catchError, from, ignoreElements, Observable, of, switchMap, tap} from "rxjs";
import {parseHistoryIdFromPath} from "../../utils/utils";
import {history} from "../../components/CustomRouter/CustomRouter";
import {API} from "../../services/api";
import {digestSetupActions} from "../digest-setup/digest-setup-slice";
import {persistenceActions} from "../persistence/persistence-slice";
import {PayloadAction} from "@reduxjs/toolkit";
import alert from "@jetbrains/ring-ui/dist/alert-service/alert-service";
import {miscActions, OnApiCallErrorActionPayload} from "./misc-slice";
import {AppConfig} from "../../config";

const ERROR_ALERT_TIMEOUT_MS = 10000;

const historyChange: Observable<string> = new Observable(subscriber => {
    const initialHistoryId = parseHistoryIdFromPath();
    if (initialHistoryId !== null) {
        subscriber.next(initialHistoryId);
    }
    history.listen(listener => {
        const historyId = parseHistoryIdFromPath(listener.location.pathname);
        if (historyId !== null && listener.action === "POP") {
            subscriber.next(historyId);
        }
    });
});

const onHistoryNavigationEvent$: Epic = (_) => historyChange.pipe(
    switchMap((newHistoryId) => API.loadStateByHistoryId(newHistoryId).pipe(
        switchMap(setup => from([
            digestSetupActions.onIncompleteDigestSetupLoadedByHistoryId(setup),
            persistenceActions.setHistoryId(newHistoryId),
        ])),
        catchError(e => {
            // if a broken historyId was used - return user to the root:
            history.push(AppConfig.rootPath);
            return of(miscActions.onApiCallError(e));
        }),
    )),
);

const onApiCallError$: Epic = (action$) => action$.pipe(
    ofType(miscActions.onApiCallError),
    tap((action: PayloadAction<OnApiCallErrorActionPayload>) => {
        console.warn("Error object:");
        console.warn({...action.payload});
        const code = action.payload.response?.status;
        if (code === 401) {
            const redirectUrl = `${AppConfig.apiHost.replace("/api", "")}/oauth2/authorization/google`;
            window.location.assign(redirectUrl);
        }
        alert.error(
            `An error has occurred for request to ${action.payload.config?.url} (status code: ${code})`,
            ERROR_ALERT_TIMEOUT_MS,
        );
    }),
    ignoreElements(),
);

export const miscEpic = combineEpics(
    onHistoryNavigationEvent$,
    onApiCallError$,
);
