import React from "react";
import {FaMinusCircle, FaPlusCircle} from "react-icons/fa";
import Button from "@jetbrains/ring-ui/dist/button/button";
import Tooltip from "@jetbrains/ring-ui/dist/tooltip/tooltip";
import {AiFillSetting} from "react-icons/ai";
import {IoCheckmarkDone, IoCheckmarkOutline} from "react-icons/io5";
import * as S from "./styles";

interface Props {
    title: string;
    stateInDigest: string;
    sentFromJetBlog: boolean;
    selected: boolean;
    onSelected?: () => void;
    onUnselected?: () => void;
    onEditButtonClick?: () => void;
}

export const PostsListItem: React.FC<Props> = (props) => {

    const {title, stateInDigest, sentFromJetBlog, selected, onSelected, onUnselected, onEditButtonClick} = props;

    return <S.ItemContainer>
        <Tooltip title={sentFromJetBlog ? "Sent via JetBlog" : "Wasn't sent via JetBlog"}>
            <IoCheckmarkOutline style={{color: sentFromJetBlog ? "darkgreen" : "#CCC"}} />
        </Tooltip>
        <Tooltip title={stateInDigest === "ok" ? "Sent in digest" : "Wasn't sent in digest"}>
            <IoCheckmarkDone style={{color: stateInDigest === "ok" ? "darkgreen" : "#CCC", marginRight: "4px"}} />
        </Tooltip>
        <p style={{flexGrow: 1}}>{title}</p>
        {selected && <Button icon={AiFillSetting} onClick={onEditButtonClick} />}
        {selected
            ? <Button icon={FaMinusCircle} onClick={onUnselected}/>
            : <Button icon={FaPlusCircle} onClick={onSelected}/>
        }
    </S.ItemContainer>;

};
