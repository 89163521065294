import React, {useEffect} from "react";
import * as S from "./styles";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {historyActions} from "../../redux/history/history-slice";
import Loader from "@jetbrains/ring-ui/dist/loader/loader";
import {Product} from "../../types/product";
import {
    SiClion, SiDatagrip,
    SiDotnet, SiGoland,
    SiIntellijidea,
    SiJetbrains,
    SiKotlin,
    SiPhpstorm,
    SiPycharm, SiResharper,
    SiTeamcity,
    SiWebstorm
} from "react-icons/si";
import Button from "@jetbrains/ring-ui/dist/button/button";
import {Textfit} from "new-react-textfit";
import {API} from "../../services/api";
import {persistenceActions} from "../../redux/persistence/persistence-slice";
import {digestSetupActions} from "../../redux/digest-setup/digest-setup-slice";
import {useNavigate} from "react-router-dom";
import {Page} from "../../types/pages";
import {AiFillEdit} from "react-icons/ai";
import {VscPreview} from "react-icons/vsc";
import {BiLinkExternal} from "react-icons/bi";

export const HistoryPage: React.FC = () => {

    const dispatch = useAppDispatch();
    const history = useAppSelector(state => state.history.history);
    const navigate = useNavigate();

    useEffect(() => {
        if (history === null) {
            dispatch(historyActions.loadHistory());
        }
    }, []);

    function restore(id: string) {
        API.restoreDigestConfiguration(id).subscribe(configuration => {
            API.updateHistoryId(configuration).subscribe(historyId => {
                dispatch(persistenceActions.setHistoryId(historyId));
                dispatch(digestSetupActions.onIncompleteDigestSetupLoadedByHistoryId(configuration));
            });
        });
    }

    function openPreview(id: string) {
        navigate(`${Page.PREVIEW}?digestId=${id}`);
    }

    function openStats(id: string) {
        const url = `https://mari.europe-north1-gke.intellij.net/abm/digests/?id=${id}`;
        window.open(url, "_blank");
    }

    return <S.Container>
        {history === null
            ? <S.LoadingBlock>
                <Loader/>
                <p>Loading history...</p>
            </S.LoadingBlock>
            : <S.List>
                {history.map(i => <S.HistoryItem key={i.id}>
                    <div style={{display: "flex", flexFlow: "row nowrap", gap: "8px", alignItems: "center", width: "100%"}}>
                        <S.ProductInfo>
                            <S.ProductIcon>{resolveIcon(i.product)}</S.ProductIcon>
                            <S.ProductTitleContainer>
                                <Textfit mode="single" min={2} max={16}>
                                    {i.product.toUpperCase()}
                                </Textfit>
                            </S.ProductTitleContainer>
                        </S.ProductInfo>
                        <div style={{textAlign: "center", flexGrow: "1"}}>
                            <S.DigestTitle>{formatTitle(i.configuration.subject)}</S.DigestTitle>
                            <S.SentBadge>Sent: {i.sentAt.toLocaleString("ru-RU")}</S.SentBadge>
                        </div>
                    </div>
                    <S.ButtonsContainer>
                        <Button onClick={() => restore(i.id)}><AiFillEdit className="fixed-icon"/> Edit</Button>
                        <Button onClick={() => openPreview(i.id)}><VscPreview className="fixed-icon"/> Preview</Button>
                        <Button onClick={() => openStats(i.id)}><BiLinkExternal className="fixed-icon"/> Open in MARI</Button>
                    </S.ButtonsContainer>
                </S.HistoryItem>)}
            </S.List>
        }
    </S.Container>;

};

function resolveIcon(product: Product): JSX.Element {
    switch (product.toLowerCase()) {
        case "jetbrains": return <SiJetbrains/>;
        case "idea": return <SiIntellijidea/>;
        case "dotnet": return <SiDotnet/>;
        case "phpstorm": return <SiPhpstorm/>;
        case "webstorm": return <SiWebstorm/>;
        case "teamcity": return <SiTeamcity/>;
        case "pycharm": return <SiPycharm/>;
        case "kotlin": return <SiKotlin/>;
        case "resharper": return <SiResharper/>;
        case "clion": return <SiClion/>;
        case "datagrip": return <SiDatagrip/>;
        case "goland": return <SiGoland/>;
        default: return <SiJetbrains/>;
    }
}

function formatTitle(title: string | null): string {
    if (!title) {
        return "[no title]";
    }
    return title.replace("=?UTF-8?Q?=F0=9F=8E=93?=", "🎓");
}
