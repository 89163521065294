import {configureStore} from "@reduxjs/toolkit";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {combineEpics, createEpicMiddleware} from "redux-observable";
import subscribeActionMiddleware from 'redux-subscribe-action';
import {digestSetupReducer} from "./digest-setup/digest-setup-slice";
import {digestSetupEpics} from "./digest-setup/digest-setup-epics";
import {sendoutsReducer} from "./sendouts/sendouts-slice";
import {sendoutsEpic} from "./sendouts/sendouts-epics";
import {blogPostsReducer} from "./blog-posts/blog-posts-slice";
import {blogPostsEpic} from "./blog-posts/blog-posts-epics";
import {persistenceReducer} from "./persistence/persistence-slice";
import {persistenceEpic} from "./persistence/persistence-epics";
import {miscReducer} from "./misc/misc-slice";
import {miscEpic} from "./misc/misc-epics";
import {historyReducer} from "./history/history-slice";
import {historyEpics} from "./history/history-epics";
import {usersReducer} from "./users/users-slice";
import {usersEpic} from "./users/users-epic";

const epicsMiddleware = createEpicMiddleware();

export const store = configureStore({
    reducer: {
        digestSetup: digestSetupReducer,
        sendouts: sendoutsReducer,
        blogPosts: blogPostsReducer,
        persistence: persistenceReducer,
        misc: miscReducer,
        history: historyReducer,
        users: usersReducer,
    },
    middleware: [epicsMiddleware, subscribeActionMiddleware],
});

const rootEpic = combineEpics(
    digestSetupEpics,
    sendoutsEpic,
    blogPostsEpic,
    persistenceEpic,
    miscEpic,
    historyEpics,
    usersEpic,
);

epicsMiddleware.run(rootEpic);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
