import styled from "styled-components";
import RingButton from "@jetbrains/ring-ui/dist/button/button";

export const DialogContent = styled.div`
  padding: 16px;
`;

export const Button = styled(RingButton)`
  margin: 0 2px;
`;

export const CheckboxContainer = styled.div`
  margin-bottom: 8px;
  font-size: 0.8em;
  line-height: 16px;
`;
