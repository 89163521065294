import React, {BaseSyntheticEvent, useState} from "react";
import Dialog from "@jetbrains/ring-ui/dist/dialog/dialog";
import {EditedBlogPost} from "../../types/blog-post";
import Input from "@jetbrains/ring-ui/dist/input/input";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import Checkbox from "@jetbrains/ring-ui/dist/checkbox/checkbox";
import {digestSetupActions} from "../../redux/digest-setup/digest-setup-slice";
import MDEditor, {commands} from '@uiw/react-md-editor';
import * as S from "./styles";
import {Size} from "@jetbrains/ring-ui/dist/input/input";

export interface EditPostDialogProps {
    post: EditedBlogPost;
    onClose: () => void;
}

export const EditPostDialog: React.FC<EditPostDialogProps> = (props) => {

    const {post, onClose} = props;
    const dispatch = useAppDispatch();
    const {selectedPosts} = useAppSelector(state => state.digestSetup);

    const [title, setTitle] = useState(post.title);
    const [description, setDescription] = useState(post.entryText);
    const [useImage, setUseImage] = useState(post.useFeaturedImage);
    const [isFeaturedPost, setFeaturedFlag] = useState(post.featuredPost);

    const save = () => {
        const posts = selectedPosts.map(p => {
            if (p.id === post.id) {
                return new EditedBlogPost(post.id, title, description, useImage, isFeaturedPost, post.meta);
            } else {
                return p;
            }
        });
        dispatch(digestSetupActions.setSelectedPosts(posts));
        onClose();
    };

    const reset = () => {
        setTitle(post.meta.originalTitle);
        setDescription(post.meta.originalDescription);
        setUseImage(true);
    };

    return <Dialog show={true} onCloseAttempt={onClose}>
        <S.DialogContent>

            <h2 style={{marginTop: 0}}>Blog settings</h2>

            <Input
                label={"Blog title"}
                size={Size.FULL}
                value={title}
                onChange={(e: BaseSyntheticEvent) => setTitle(e.target.value)}
            />

            <MDEditor
                value={description}
                preview="edit"
                onChange={(value) => setDescription(value!)}
                style={{marginBottom: "8px"}}
                commands={[
                    commands.bold,
                    commands.italic,
                    commands.link,
                    commands.unorderedListCommand,
                    commands.orderedListCommand,
                ]}
                extraCommands={[
                    commands.codeEdit,
                    commands.codeLive,
                    commands.codePreview,
                ]}
            />

            <S.CheckboxContainer>
                <Checkbox checked={useImage} onChange={() => setUseImage(!useImage)} />
                <label>Use featured image (if available)</label>
            </S.CheckboxContainer>

            <S.CheckboxContainer>
                <Checkbox checked={isFeaturedPost} onChange={() => setFeaturedFlag(!isFeaturedPost)} />
                <label>Featured post</label>
            </S.CheckboxContainer>

            <div style={{display: "flex", flexFlow: "row wrap", justifyContent: "space-between"}}>
                <S.Button onClick={reset}>Reset blog</S.Button>
                <div>
                    <S.Button onClick={save} primary>Apply</S.Button>
                    <S.Button onClick={onClose}>Close</S.Button>
                </div>
            </div>

        </S.DialogContent>
    </Dialog>;

};
