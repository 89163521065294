import {combineEpics, Epic, ofType, StateObservable} from "redux-observable";
import {digestSetupActions} from "./digest-setup-slice";
import {catchError, debounceTime, filter, from, map, mergeMap, of, switchMap} from "rxjs";
import {PayloadAction} from "@reduxjs/toolkit";
import {Product} from "../../types/product";
import {API} from "../../services/api";
import {RootState} from "../store";
import {sendoutsActions} from "../sendouts/sendouts-slice";
import {blogPostsActions} from "../blog-posts/blog-posts-slice";
import {persistenceActions} from "../persistence/persistence-slice";
import {miscActions} from "../misc/misc-slice";
import {EditedBlogPost, IncompleteDigestSetup} from "../../types/blog-post";
import {DigestSetup} from "../../types/digest-setup";

const INPUTS_DEBOUNCE_MS = 500;

const onProductSelected$: Epic = (action$) => action$.pipe(
    ofType(digestSetupActions.setProduct),
    switchMap((action: PayloadAction<Product>) => {
        console.log("here", action)
        return from([
            digestSetupActions.loadProductDefaults(action.payload),
            blogPostsActions.setLoadedPosts(null),
            blogPostsActions.loadPosts({preserveSelected: false}),
            digestSetupActions.setSelectedPosts([]),
            sendoutsActions.updateSendoutAllowance(),
        ])
    }),
);

const loadProductDefaults$: Epic = (action$) => action$.pipe(
    ofType(digestSetupActions.loadProductDefaults),
    switchMap((action: PayloadAction<Product>) => API.loadProductDefaults(action.payload).pipe(
        map(defaults => digestSetupActions.onProductDefaultsLoaded(defaults)),
        catchError(e => of(miscActions.onApiCallError(e))),
    )),
);

const onSubjectChange$: Epic = (action$, state: StateObservable<RootState>) => action$.pipe(
    ofType(digestSetupActions.setSubject),
    filter((action: PayloadAction<string>) => action.payload !== ""),
    debounceTime(INPUTS_DEBOUNCE_MS),
    switchMap(() => from(state.value.digestSetup.setupIsValid ? [
        persistenceActions.updatePreviewLink(),
        persistenceActions.updateHistoryId()
    ] : [])),
);

const onSubheaderChange$: Epic = (action$, state: StateObservable<RootState>) => action$.pipe(
    ofType(digestSetupActions.setSubheader),
    filter((action: PayloadAction<string>) => action.payload !== ""),
    debounceTime(INPUTS_DEBOUNCE_MS),
    switchMap(() => from(state.value.digestSetup.setupIsValid ? [
        persistenceActions.updatePreviewLink(),
        persistenceActions.updateHistoryId()
    ] : [])),
);

const onSenderNameChange$: Epic = (action$, state$) => action$.pipe(
    ofType(digestSetupActions.setSenderName),
    filter((action: PayloadAction<string>) => action.payload !== ""),
    debounceTime(INPUTS_DEBOUNCE_MS),
    switchMap(() => from(state$.value.digestSetup.setupIsValid ? [
        persistenceActions.updatePreviewLink(),
        persistenceActions.updateHistoryId()
    ] : [])),
);

const onIntroductionChange$: Epic = (action$, state: StateObservable<RootState>) => action$.pipe(
    ofType(digestSetupActions.setIntroduction),
    filter((action: PayloadAction<string>) => action.payload !== ""),
    debounceTime(INPUTS_DEBOUNCE_MS),
    switchMap(() => from(state.value.digestSetup.setupIsValid ? [
        persistenceActions.updatePreviewLink(),
        persistenceActions.updateHistoryId()
    ] : [])),
);

const onSelectedPostsChange$: Epic = (action$, state: StateObservable<RootState>) => action$.pipe(
    ofType(digestSetupActions.setSelectedPosts),
    debounceTime(INPUTS_DEBOUNCE_MS),
    switchMap(() => from(state.value.digestSetup.setupIsValid ? [
        persistenceActions.updatePreviewLink(),
        persistenceActions.updateHistoryId()
    ] : [])),
);

/**
 * Sometimes when we load a digest setup by history ID - we can not have blog-posts meta-information (like
 * state-in-blog, original title, etc.), because some posts may belong to other products (not to a selected one).
 * Normally we can look for that data in the list of loaded posts, but in the situation described above we have
 * to load missing information individually for every such post. This is what this epic is created for.
 */
const onIncompleteDigestSetupLoadedByHistoryId$: Epic = (action$, state: StateObservable<RootState>) => action$.pipe(
    ofType(digestSetupActions.onIncompleteDigestSetupLoadedByHistoryId),
    mergeMap(async (action: PayloadAction<IncompleteDigestSetup>) => {
        const incompleteSetup = action.payload;
        const posts: EditedBlogPost[] = [];
        for (const post of incompleteSetup.include) {
            const originalPost = state.value.blogPosts.loadedPosts?.find(p => p.id === post.id);
            if (originalPost) {
                posts.push(new EditedBlogPost(
                    post.id,
                    post.title,
                    post.entryText,
                    post.useFeaturedImage ?? null,
                    post.featuredPost ?? null,
                    {
                        stateInDigest: originalPost.stateInDigest,
                        originalTitle: originalPost.title,
                        originalDescription: originalPost.description,
                        sentFromJetBlog: originalPost.sentFromJetBlog,
                    },
                ));
            } else {
                try {
                    const loadedPost = (await API.getPostById(post.id).toPromise())!;
                    posts.push(new EditedBlogPost(
                        post.id,
                        post.title,
                        post.entryText,
                        post.useFeaturedImage ?? null,
                        post.featuredPost ?? null,
                        {
                            stateInDigest: loadedPost.stateInDigest,
                            originalTitle: loadedPost.title,
                            originalDescription: loadedPost.description,
                            sentFromJetBlog: loadedPost.sentFromJetBlog,
                        },
                    ));
                } catch (e) {
                    return miscActions.onApiCallError(e as any);
                }
            }
        }
        const setup: DigestSetup = {
            product: incompleteSetup.product,
            introduction: incompleteSetup.introduction,
            subject: incompleteSetup.subject,
            subheader: incompleteSetup.subheader,
            senderName: incompleteSetup.senderName,
            include: posts,
        };
        return digestSetupActions.onDigestSetupLoadedByHistoryId(setup);
    }),
);

const onDigestSetupLoadedByHistoryId$: Epic = (action$) => action$.pipe(
    ofType(digestSetupActions.onDigestSetupLoadedByHistoryId),
    switchMap(() => from([
        persistenceActions.updatePreviewLink(),
        blogPostsActions.loadPosts({preserveSelected: true}),
        sendoutsActions.updateSendoutAllowance(),
    ])),
);

export const digestSetupEpics = combineEpics(
    onProductSelected$,
    loadProductDefaults$,
    onSubjectChange$,
    onSubheaderChange$,
    onSenderNameChange$,
    onIntroductionChange$,
    onSelectedPostsChange$,
    onDigestSetupLoadedByHistoryId$,
    onIncompleteDigestSetupLoadedByHistoryId$,
);
