import {PostState} from "./post-state";
import {Product} from "./product";

// how it comes from the database:
export interface BlogPost {
    date: number;
    description: string;
    id: string;
    stateInDigest: PostState;
    sentFromJetBlog: boolean;
    title: string;
}

// how it should be sent back after editing:
export class EditedBlogPost {

    readonly useFeaturedImage: boolean;
    readonly featuredPost: boolean;

    constructor(
        public readonly id: string,
        public readonly title: string,
        public readonly entryText: string,
        useFeaturedImage: boolean | null,
        featuredPost: boolean | null,
        public readonly meta: EditedBlogPostMeta,
    ) {
        this.useFeaturedImage = useFeaturedImage ?? true;
        this.featuredPost = featuredPost ?? false;
    }

    static from(post: BlogPost): EditedBlogPost {
        return new EditedBlogPost(
            post.id,
            post.title,
            post.description,
            true,
            false,
            {
                stateInDigest: post.stateInDigest,
                originalTitle: post.title,
                originalDescription: post.description,
                sentFromJetBlog: post.sentFromJetBlog,
            },
        );
    }

}

export interface EditedBlogPostMeta {
    stateInDigest: PostState;
    sentFromJetBlog: boolean;
    originalTitle: string;
    originalDescription: string;
}

/**
 * The same as normal, but posts here are without EditedBlogPostMeta. It's a "raw" data loaded from the backend,
 * but further this structure must be supplemented with that EditedBlogPostMeta.
 */
export interface IncompleteDigestSetup {
    product: Product;
    introduction: string;
    subheader: string;
    subject: string;
    senderName: string;
    include: {
        id: string,
        title: string,
        entryText: string,
        useFeaturedImage?: boolean | null,
        featuredPost?: boolean | null,
    }[];
}
