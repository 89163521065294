import React from 'react';
import Link from '@jetbrains/ring-ui/dist/link/link';
import * as S from "./styles";
import {Header} from "../Header/Header";
import {EditorPage} from "../EditorPage/EditorPage";
import {Route, Routes} from "react-router-dom";
import {Page} from "../../types/pages";
import {HistoryPage} from "../HistoryPage/HistoryPage";
import {PreviewPage} from "../PreviewPage/PreviewPage";

export const AppRoot = () => {

    return <S.RootLayout>
        <Header/>
        <S.MainLayout>
            <Routes>
                <Route element={<EditorPage/>} path={Page.EDITOR}/>
                <Route element={<EditorPage/>} path={"/editor/*"}/>
                <Route element={<HistoryPage/>} path={Page.HISTORY}/>
                <Route element={<PreviewPage/>} path={Page.PREVIEW}/>
            </Routes>
        </S.MainLayout>
        <S.Footer>
            <S.Link href="https://youtrack.jetbrains.com/articles/MKT-A-236159878" target="_blank">User Manual</S.Link>
            <S.Link href="https://youtrack.jetbrains.com/newIssue?project=MAU&draftId=25-5203441" target="_blank">Report an Issue</S.Link>
            <S.Link href="https://jetblog.labs.jb.gg/sendouts" target="_blank">JetBlog</S.Link>
            <S.Link href="https://jetbrains.slack.com/archives/C060BGP1C" target="_blank">Contact Us</S.Link>
        </S.Footer>
    </S.RootLayout>;

};
