import React, {useEffect, useRef} from "react";
import {useSearchParams} from "react-router-dom";
import * as S from "./styles";
import {AppConfig} from "../../config";

export const PreviewPage: React.FC = () => {

    const digestId = useSearchParams()[0].get("digestId");
    const iframeRef = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        if (digestId) {
            // this stuff is to avoid iframe's affection on browser history (using default "src" prop of an iframe):
            const host = AppConfig.apiHost.startsWith("/") ? (window.location.origin + AppConfig.apiHost) : AppConfig.apiHost;
            const url = new URL(host + "/digest/preview");
            url.searchParams.set("id", digestId);
            console.log(`Loading a preview for digest with id ${digestId}`);
            iframeRef.current!.contentWindow!.location.replace(url);
        }
    }, [digestId]);

    return <S.Container>
        <S.Iframe ref={iframeRef}/>
    </S.Container>;

};
