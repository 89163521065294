import {combineEpics, Epic, ofType} from "redux-observable";
import {historyActions} from "./history-slice";
import {catchError, map, of, switchMap} from "rxjs";
import {API} from "../../services/api";
import {miscActions} from "../misc/misc-slice";

const loadHistory$: Epic = (action$) => action$.pipe(
    ofType(historyActions.loadHistory),
    switchMap(() => API.getDigestsHistory().pipe(
        map(history => historyActions.onHistoryLoaded(history)),
        catchError(e => of(miscActions.onApiCallError(e))),
    )),
);

export const historyEpics = combineEpics(
    loadHistory$,
);
