import {map, Observable} from "rxjs";
import {ProductDefaults} from "../types/product-defaults";
import {Product} from "../types/product";
import {DigestSetup} from "../types/digest-setup";
import {BlogPost, IncompleteDigestSetup} from "../types/blog-post";
import axios from "axios-observable";
import {ProductDescriptor} from "../types/product-descriptor";
import {AppConfig} from "../config";
import {DigestSendout} from "../types/digest-sendout";
import {CurrentUserInfo} from "../types/current-user-info";

const host: string = AppConfig.apiHost;

export namespace API {

    export function getProductsList(): Observable<ProductDescriptor[]> {
        log("Loading products list");
        return axios.get(`${host}/products`, {withCredentials: true}).pipe(
            map(response => response.data.map((entry: any) => entry) as ProductDescriptor[]),
        );
    }

    export function loadProductDefaults(product: Product): Observable<ProductDefaults> {
        log(`Loading product's defaults (product=${product})`);
        return axios.get(`${host}/digest/${product}/defaults`, {withCredentials: true}).pipe(
            map(response => response.data as ProductDefaults),
        );
    }

    // returns a link for iframe
    export function updatePreview(request: DigestSetup): Observable<string> {
        log(`Updating preview with parameters ${JSON.stringify(request)}`);
        return axios.post(`${host}/digest/preview`, sanitizeMeta(request), {withCredentials: true}).pipe(
            map(response => response.data._links.preview.href),
        );
    }

    export function updateHistoryId(request: DigestSetup | IncompleteDigestSetup): Observable<string> {
        log(`Updating a history ID with parameters ${JSON.stringify(request)}`);
        const _request = Object.hasOwn(request, "meta") ? sanitizeMeta(request as DigestSetup) : request;
        return axios.post(`${host}/digest/history`, _request, {withCredentials: true}).pipe(
            map(response => response.data.id),
        );
    }

    export function loadPosts(product: Product): Observable<BlogPost[]> {
        log(`Loading posts for ${product}`);
        return axios.get(`${host}/blogs/${product}`, {withCredentials: true}).pipe(
            map((response: any) => response.data.blogs as BlogPost[]),
        );
    }

    export function getPostById(id: string): Observable<BlogPost> {
        log(`Loading post with id=${id}`);
        return axios.get(`${host}/blog?id=${encodeURIComponent(id)}`, {withCredentials: true}).pipe(
            map(response => response.data as BlogPost),
        );
    }

    export function loadStateByHistoryId(historyId: string): Observable<IncompleteDigestSetup> {
        log(`Loading saved state by history id ${historyId}`);
        return axios.get(`${host}/digest/history/${historyId}`, {withCredentials: true}).pipe(
            map(response => response.data as IncompleteDigestSetup),
        );
    }

    export function getSendoutAllowance(product: Product): Observable<boolean> {
        log(`Checking allowance for product ${product}`);
        return axios.get(`${host}/digest/allowed/${product}`, {withCredentials: true}).pipe(
            map(response => response.data.allowed === true),
        );
    }

    export function sendTestEmail(request: DigestSetup): Observable<any> {
        log(`Sending a test email`);
        return axios.post(`${host}/digest/sample`, sanitizeMeta(request), {withCredentials: true});
    }

    export function sendDigest(request: DigestSetup): Observable<any> {
        log(`Sending the digest`);
        return axios.post(`${host}/digest`, sanitizeMeta(request), {withCredentials: true});
    }

    export function getDigestsHistory(): Observable<DigestSendout[]> {
        log(`Retrieving a history`);
        return axios.get(`${host}/digests`, {withCredentials: true}).pipe(
            map(response => (response.data as any[]).map(i => DigestSendout.parse(i))),
        );
    }

    export function restoreDigestConfiguration(id: string): Observable<IncompleteDigestSetup> {
        log(`Restoring configuration for the digest with id ${id}`);
        return axios.post(`${host}/digest/restore`, {}, {withCredentials: true, params: {id}}).pipe(
            map(response => response.data as IncompleteDigestSetup),
        );
    }

    export function getCurrentUserInfo(): Observable<CurrentUserInfo> {
        log('Loading current user info');
        return axios.get(`${host}/users/me`, {withCredentials: true}).pipe(
            map(response => response.data as CurrentUserInfo),
        );
    }

    function log(msg: string) {
        console.log(`API: ${msg}`);
    }

    function sanitizeMeta(digestSetup: DigestSetup): IncompleteDigestSetup {
        return {
            ...digestSetup,
            include: digestSetup.include.map(post => {
                return (({meta, ...other}) => other)(post);
            })
        };
    }

}