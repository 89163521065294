import React, {useEffect, useRef} from 'react';
import {useAppSelector} from "../../redux/store";
import Loader from "@jetbrains/ring-ui/dist/loader/loader";
import * as S from "./styles";

export const Preview = () => {

    const iframeRef = useRef<HTMLIFrameElement>(null);
    const {previewLink, updatingPreview} = useAppSelector(state => state.persistence);
    useEffect(() => {
        if (previewLink !== null) {
            // this stuff is to avoid iframe's affection on browser history (using default "src" prop of an iframe):
            iframeRef.current!.contentWindow!.location.replace(previewLink)
        }
    }, [previewLink]);

    return <div style={{width: "100%", height: "100%", position: "relative"}}>
        {updatingPreview && <S.LoaderOverlay>
            <S.LoaderAnimationHolder>
                <Loader message="Updating preview..."/>
            </S.LoaderAnimationHolder>
        </S.LoaderOverlay>}
        <S.IFrame ref={iframeRef}/>
    </div>;

};
