import styled from "styled-components";

export const Container = styled.div`
  padding: 16px;
`;

export const List = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  gap: 16px;
  align-items: center;
`;

export const HistoryItem = styled.div`
  width: 460px;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  padding: 20px;
`;

export const LoadingBlock = styled.div`
  margin: auto;
  width: 200px;
  text-align: center;
`;

export const ProductInfo = styled.div`
  flex-shrink: 0;
  width: 60px;
  overflow: hidden;
`;

export const ProductIcon = styled.span`
  display: block;
  width: 60px;
  height: 60px;
  overflow: hidden;
  font-size: 60px;
  border-radius: 4px;
`;

export const ProductTitleContainer = styled.div`
  font-weight: 600;
  text-align: center;
`;

export const DigestTitle = styled.p`
  margin: 0 0 8px;
  font-weight: 600;
  font-size: 1.1em;
`;

export const SentBadge = styled.p`
  font-size: 0.85em;
  background-color: lightgreen;
  display: block;
  padding: 4px 8px;
  border-radius: 8px;
  width: fit-content;
  text-align: center;
  margin: auto;
`;

export const ButtonsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  justify-content: center;
`;
