import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export type OnApiCallErrorActionPayload = {config?: {url?: string}, response?: {status?: number}};

export interface MiscState {
    // not needed for now
}

const initialMiscState: MiscState = {};

const miscSlice = createSlice({
    initialState: initialMiscState,
    name: "misc-slice",
    reducers: {
        onApiCallError: (state, action: PayloadAction<OnApiCallErrorActionPayload>) => {},
    },
});

export const miscActions = miscSlice.actions;

export const miscReducer = miscSlice.reducer;
