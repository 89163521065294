import styled from "styled-components";

export const RootLayout = styled.div`
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
`;

export const MainLayout = styled.main`
  flex-grow: 1;
  width: 100vw;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  #main-content-left {
    flex: 2 0;
  }
  #main-content-right {
    flex: 1 0;
  }
`;

export const Footer = styled.footer`
  height: 40px;
  line-height: 40px;
  font-size: 0.9em;
  color: whitesmoke;
  background-color: black;
  width: 100vw;
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;
  justify-content: flex-end;
  padding: 0 16px;
`;

export const Link = styled.a`
  color: white;
  text-decoration: none;
  transition: color ease-in-out 0.3s;
  &:hover {
    color: #f50057;
  }
  &:not(&:last-child):after {
    content: "|";
    display: inline-block;
    margin-left: 16px;
    color: #777 !important;
  }
`;
