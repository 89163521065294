import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface SendoutsState {
    sendoutAllowed: boolean;
    sampleEmailSendingInProgress: boolean;
    digestSendingInProgress: boolean;
}

const initialSendoutsState: SendoutsState = {
    sendoutAllowed: false,
    sampleEmailSendingInProgress: false,
    digestSendingInProgress: false,
};

const sendoutsSlice = createSlice({
    initialState: initialSendoutsState,
    name: "sendouts-slice",
    reducers: {
        updateSendoutAllowance: () => {},
        onSendoutAllowanceUpdated: (state, action: PayloadAction<boolean>) => {
            state.sendoutAllowed = action.payload;
        },
        sendTestEmail: (state) => {
            state.sampleEmailSendingInProgress = true;
        },
        onTestEmailSent: (state) => {
            state.sampleEmailSendingInProgress = false;
        },
        sendDigest: (state) => {
            state.digestSendingInProgress = true;
        },
        onDigestSent: (state) => {
            state.digestSendingInProgress = false;
        },
    },
});

export const sendoutsActions = sendoutsSlice.actions;

export const sendoutsReducer = sendoutsSlice.reducer;
