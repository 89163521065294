import {CurrentUserInfo} from "../../types/current-user-info";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface UsersState {
    currentUser: CurrentUserInfo | null;
}

const initialState: UsersState = {
    currentUser: null,
};

const usersSlice = createSlice({
    name: "users",
    initialState: initialState,
    reducers: {
        loadCurrentUser: () => {},
        onCurrentUserLoaded: (state, action: PayloadAction<CurrentUserInfo>) => {
            state.currentUser = action.payload;
        },
    },
});

export const usersReducer = usersSlice.reducer;
export const usersActions = usersSlice.actions;
